import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createSelector } from 'reselect';
import ReactRouterPropTypes from 'react-router-prop-types';

import { surveyDetailThunk } from 'store/actions/surveys';
import { surveyAnswersListThunk } from 'store/actions/answers';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { surveyPropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';

import SurveyAdminDropdownButton from 'components/shared/SurveyAdminDropdownButton';
import Question from './Question';
import SurveyResultsHeader from './SurveyResultsHeader';

class SurveyResultsPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    surveyDetailThunk: PropTypes.func.isRequired,
    surveyAnswersListThunk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    notFound: PropTypes.bool.isRequired,
    survey: surveyPropTypes,
    questions: PropTypes.arrayOf(PropTypes.object),
    requestCurrentUser: PropTypes.func.isRequired,
    userIsAdmin: PropTypes.bool,
  };

  state = {
    printFormat: false,
  };

  componentDidMount() {
    const { surveyId } = this.props.match.params;
    this.props.surveyDetailThunk(surveyId);
    this.props.surveyAnswersListThunk(surveyId);
    this.props.requestCurrentUser();
  }

  render() {
    const { isLoading, hasErrored, notFound, survey, questions, userIsAdmin } = this.props;
    if (notFound) {
      return <Redirect to="/404" />;
    }
    if (hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Please try to reload the page or try again later.
        </Alert>
      );
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="survey">
        <button
          type="button"
          className="btn btn-default pull-right"
          style={{ margin: '10px' }}
          onClick={() => this.setState({ printFormat: true }, () => window.print())}
        >
          <i className="fa fa-print icon-space-r" />
          Print
        </button>
        {userIsAdmin && (
          <div className="pull-right" style={{ margin: '12px 0' }}>
            <SurveyAdminDropdownButton id={survey.id} />
          </div>
        )}
        <SurveyResultsHeader survey={survey} />
        {questions.map((q) => (
          <Question key={q.id} question={q} printFormat={this.state.printFormat} />
        ))}
      </div>
    );
  }
}

const selectQuestions = (survey, questions, answers) => {
  if (!survey || !survey.question_blocks || Object.keys(questions).length === 0) {
    return [];
  }
  const questionIds = survey.question_blocks.reduce((accum, questionBlock) => {
    return accum.concat(questionBlock.questions);
  }, []);
  const result = [];
  for (let idx = 0; idx < questionIds.length; idx++) {
    let qId = questionIds[idx];
    let q = questions[qId];
    if (!q || q.isLoading || !q.answers) {
      return [];
    }
    q.answers = q.answers
      .map((aId) => {
        if (typeof aId === 'object') {
          return answers[aId.id];
        }
        return answers[aId];
      })
      .filter(Boolean);
    if (q.question_type === 'choice-matrix') {
      const choicesDict = q.metadata.choiceMatrix.choices.reduce((acc, choice) => {
        acc[choice.value] = choice.text;
        return acc;
      }, {});
      q.results = q.answers.reduce((acc, answer) => {
        Object.entries(answer.response.value).forEach((entry) => {
          if (!acc[entry[0]]) {
            acc[entry[0]] = Object.values(choicesDict).reduce((acc, choice) => {
              acc[choice] = 0;
              return acc;
            }, {});
          }
          acc[entry[0]][choicesDict[entry[1]]] = acc[entry[0]][choicesDict[entry[1]]] + 1;
        });
        return acc;
      }, {});
    } else if (['yes-no', 'yes-no-comment', 'choice'].includes(q.question_type)) {
      q.results = q.answers.reduce((acc, answer) => {
        acc[answer.response.value] = (acc[answer.response.value] || 0) + 1;
        return acc;
      }, {});
    } else if (['choice-multiple', 'choice-multiple-freeform'].includes(q.question_type)) {
      q.results = q.answers.reduce((acc, answer) => {
        answer.response.value.forEach((val) => {
          acc[val] = (acc[val] || 0) + 1;
        });
        return acc;
      }, {});
    }
    result.push(q);
  }

  return result.sort((a, b) => (a.number < b.number ? -1 : 1));
};

const surveySelector = (state, props) => state.surveys.keys[props.match.params.surveyId];
const questionsSelector = (state) => state.questions.keys;
const answersSelector = (state) => state.answers.keys;
const getQuestionsFromSurvey = createSelector(
  [surveySelector, questionsSelector, answersSelector],
  selectQuestions
);

const mapStateToProps = (state, props) => {
  const { surveys, answers, accountsUser } = state;
  const { surveyId } = props.match.params;
  const survey = surveys.keys[surveyId];
  const isLoading = !survey || survey.isLoading || answers.keys.isLoading;
  const hasErrored = (survey && survey.hasErrored) || answers.keys.hasErrored;
  const notFound = !!(survey && survey.notFound);
  const user = accountsUser[accountsUser.current];
  const userIsAdmin = user && user.isAdmin;
  return {
    isLoading,
    hasErrored,
    notFound,
    survey,
    questions: !isLoading && !hasErrored && !notFound ? getQuestionsFromSurvey(state, props) : [],
    userIsAdmin,
  };
};

export default connect(
  mapStateToProps,
  { surveyDetailThunk, surveyAnswersListThunk, requestCurrentUser }
)(SurveyResultsPage);
