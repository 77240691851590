import React from 'react';
import PropTypes from 'prop-types';
import { questionPropTypes } from 'helpers/proptypes';
import QuestionResults from './QuestionResults';

const Question = ({ question, printFormat }) => {
  return (
    <div className="question-block">
      <p className="text-size">
        <strong>Q{question.number}</strong> - {question.question_text}
      </p>
      <QuestionResults question={question} printFormat={printFormat} />
    </div>
  );
};

Question.propTypes = {
  question: questionPropTypes.isRequired,
  printFormat: PropTypes.bool,
};

export default Question;
