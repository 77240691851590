import React from 'react';
import { questionPropTypes } from 'helpers/proptypes';

const ChoiceQuestionResults = ({ question }) => {
  const multipleQuestion = ['choice-multiple', 'choice-multiple-freeform'].includes(
    question.question_type
  );
  const totalAnswers = multipleQuestion
    ? Object.values(question.results).reduce((acc, result) => acc + result, 0)
    : question.answers.length;
  if (question.answers.length === 0) {
    return 'No results have been recorded.';
  }
  return (
    <div className="table-responsive">
      <table className="table table-results">
        <thead>
          <tr>
            <th className="table-results__field-column">Field</th>
            {question.metadata.choiceMatrix &&
              question.metadata.choiceMatrix.choices.map((choice) => (
                <th className="table-results__choice-column" key={choice.value} colSpan="2">
                  {choice.text}
                </th>
              ))}
            {question.metadata.choiceMatrix ? (
              <th className="table-results__total-column">Total</th>
            ) : (
              <th className="table-results__total-column" colSpan="2">
                Choice Count
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {question.metadata.choices && (
            <React.Fragment>
              {question.metadata.choices.map((choice) => {
                const choiceCount = question.results[choice.value];
                const choicePercent = choiceCount
                  ? `${((choiceCount / totalAnswers) * 100).toFixed(2)}%`
                  : '0.00%';
                return (
                  <tr key={choice.value}>
                    <td className="table-results__field-column">{choice.text}</td>
                    <td className="table-results__percent-column">{choicePercent}</td>
                    <td className="table-results__total-column">{choiceCount || 0}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="3" className="table-results__sum-column">
                  {totalAnswers}
                </td>
              </tr>
            </React.Fragment>
          )}
          {question.metadata.choiceMatrix &&
            question.metadata.choiceMatrix.labels.map((label, idx) => (
              <tr key={idx}>
                <td className="table-results__field-column">{label}</td>
                {question.metadata.choiceMatrix.choices.map((choice) => {
                  const choiceCount = question.results[label][choice.text];
                  const choicePercent = `${((choiceCount / question.answers.length) * 100).toFixed(
                    2
                  )}%`;
                  return (
                    <React.Fragment key={choice.text}>
                      <td className="table-results__percent-column">{choicePercent}</td>
                      <td>{choiceCount}</td>
                    </React.Fragment>
                  );
                })}
                <td className="table-results__total-column">{question.answers.length}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

ChoiceQuestionResults.propTypes = {
  question: questionPropTypes.isRequired,
};

export default ChoiceQuestionResults;
