import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

const LinkOrA = (props) => {
  const { href, to, ...linkProps } = props;
  if (to) {
    return <Link to={to} {...linkProps} />;
  }
  return <a href={href} {...linkProps} />;
};
LinkOrA.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};

const SurveyAdminDropdownButton = ({ id, onCopyClick, location }) => {
  const links = [
    {
      to: `/surveys/admin/${id}`,
      icon: 'fa-pencil',
      text: 'Edit',
    },
    {
      to: `/surveys/admin/${id}/distributions`,
      icon: 'fa-envelope',
      text: 'Distributions',
    },
    {
      to: window.URLS['surveys:survey_facilitator_results_detail'](id),
      icon: 'fa-bar-chart',
      text: 'Results',
    },
    onCopyClick && {
      onClick: onCopyClick,
      icon: 'fa-clone',
      text: 'Duplicate',
    },
    {
      separator: true,
    },
    {
      to: `/surveys/admin/${id}/delete`,
      icon: 'fa-trash',
      text: 'Delete',
    },
  ];

  return (
    <div className="btn-group dropdown pull-right">
      <button
        type="button"
        className="btn btn-sm btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-chevron-down" />
      </button>
      <ul className="dropdown-menu">
        {links.map((link, index) => {
          if (!link) {
            return;
          }

          if (link.separator) {
            return <li role="separator" className="divider" key={`separator-${index}`} />;
          }

          const className = location && location.pathname === link.to ? 'active' : '';

          return (
            <li className={className} key={link.text}>
              <LinkOrA to={link.to} href={link.href} onClick={link.onClick}>
                <span className={`fa ${link.icon} fa-fw icon-space-r`} />
                {link.text}
              </LinkOrA>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SurveyAdminDropdownButton.propTypes = {
  id: PropTypes.number.isRequired,
  onCopyClick: PropTypes.func,
  location: ReactRouterPropTypes.location.isRequired,
};

const SurveyAdminDropdownButtonWithRouter = withRouter(SurveyAdminDropdownButton);
export default SurveyAdminDropdownButtonWithRouter;
