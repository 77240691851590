import React from 'react';
import { questionPropTypes } from 'helpers/proptypes';

class TextQuestionResults extends React.Component {
  static propTypes = {
    question: questionPropTypes.isRequired,
  };

  state = {
    step: 0,
  };

  prevStep = () => {
    this.setState((prevState) => {
      let step = prevState.step - 1;
      step = step < 0 ? 0 : step;
      return { step };
    });
  };

  nextStep = () => {
    this.setState((prevState, prevProps) => {
      const { answers } = prevProps.question;
      let step = prevState.step + 1;
      step = step >= answers.length ? step - 1 : step;
      return { step };
    });
  };

  render() {
    const { question } = this.props;
    const { step } = this.state;
    const text = question.answers.length
      ? question.answers[step].response.value
      : 'No results have been recorded.';
    return (
      <div className="text-results">
        {step !== 0 && (
          <button className="btn btn-default text-results__previous-step" onClick={this.prevStep}>
            <i className="fa fa-chevron-left" />
          </button>
        )}
        <blockquote className="text-results__blockquote">{text}</blockquote>
        {step < question.answers.length - 1 && (
          <button className="btn btn-default text-results__next-step" onClick={this.nextStep}>
            <i className="fa fa-chevron-right" />
          </button>
        )}
      </div>
    );
  }
}

export default TextQuestionResults;
