import React from 'react';
import PropTypes from 'prop-types';
import { questionPropTypes } from 'helpers/proptypes';
import shuffle from 'lodash/shuffle';

import ChoiceQuestionResults from './ChoiceQuestionResults';
import TextQuestionResults from './TextQuestionResults';

const QuestionResults = ({ question, printFormat }) => {
  switch (question.question_type) {
    case 'yes-no':
      question.metadata = { choices: [{ text: 'Yes', value: true }, { text: 'No', value: false }] };
      return <ChoiceQuestionResults question={question} />;
    case 'yes-no-comment':
      return <div />;
    case 'yes-no-idk':
      return <ChoiceQuestionResults question={question} />;
    case 'open':
      if (printFormat) {
        return (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Response</th>
                </tr>
              </thead>
              <tbody>
                {question.answers.map((answer, idx) => (
                  <tr key={answer.id}>
                    <td>#{idx}</td>
                    <td>{answer.response.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
      question.answers = shuffle(question.answers);
      return <TextQuestionResults question={question} />;
    case 'open-text':
      if (printFormat) {
        return (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Response</th>
                </tr>
              </thead>
              <tbody>
                {question.answers.map((answer, idx) => (
                  <tr key={answer.id}>
                    <td>#{idx}</td>
                    <td>{answer.response.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
      question.answers = shuffle(question.answers);
      return <TextQuestionResults question={question} />;
    case 'choice': {
      return <ChoiceQuestionResults question={question} />;
    }
    // case 'choice-freeform':
    //   return <ChoiceFreeform question={question} />;
    case 'choice-multiple': {
      return <ChoiceQuestionResults question={question} />;
    }
    case 'choice-multiple-freeform': {
      return <ChoiceQuestionResults question={question} />;
    }
    case 'choice-matrix': {
      return <ChoiceQuestionResults question={question} />;
    }
    // case 'range':
    //   return <Range question={question} />;
    case 'number':
      return <div />;
    // case 'comment':
    //   return <Comment question={question} />;
    default:
      return <div />;
  }
};

QuestionResults.propTypes = {
  question: questionPropTypes.isRequired,
  printFormat: PropTypes.bool,
};

export default QuestionResults;
