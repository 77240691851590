import React from 'react';
import { surveyPropTypes } from 'helpers/proptypes';

const SurveyResultsHeader = ({ survey }) => {
  return (
    <div className="question-block">
      <div className="center">
        <h1>Survey Results</h1>
        <h2>{survey.event ? survey.event.title : survey.name}</h2>
      </div>
    </div>
  );
};

SurveyResultsHeader.propTypes = {
  survey: surveyPropTypes.isRequired,
};

export default SurveyResultsHeader;
